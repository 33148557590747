import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

export const roomThumbsSwiperConfig: SwiperConfigInterface = {
  slidesPerView: 4,
  pagination: false,
  navigation: false,
  mousewheel: true,
  observer: true,
  observeParents: true,
};
