import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { validateLang } from '../../config/translate.config';

@Component({
  selector: 'app-lang-select',
  templateUrl: './lang-select.component.html',
})
export class LangSelectComponent implements OnInit {

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly router: Router,
    private readonly translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    const lang = validateLang(this.route.snapshot.data['lang']);
    this.translate.use(lang);
    this.router.navigate(['/']);
  }
}
