// *******************************************************************************
// Route animations
// ":enter"  - component/view that is entering the view and was previously not loaded in dom
// ":leave"  - component/view leaving the view and will be removed from the dom
// Route transitions
// * => *   - switching from one view to other, but not back
// * <=> *  - switching from one view to another and back
// *******************************************************************************
import { animate, query, style, transition, trigger } from '@angular/animations';
const timing = '300ms ease-in-out';

const fadeIn = [
  query(':leave', style({ opacity: 1 }), { optional: true }),
  query(':enter', style({ opacity: 0 }), { optional: true }),
  query(':leave', animate(timing, style({ opacity: 0 })), { optional: true }),
  query(':enter', animate(timing, style({ opacity: 1 })), { optional: true }),
];

export const routeAnimation = trigger('routeAnimations', [transition('* <=> *', fadeIn)]);
