export const allSliderImages = {
  '1': {
    room: true,
    folder: '1',
    lang: 'rooms',
    images: ['1_IMG_7604.jpg', '3_IMG_7606.jpg', '4_IMG_7594.jpg', '5_IMG_7552.jpg', '9_IMG_7637.jpg', '2_IMG_7618.jpg', '6_IMG_7576.jpg', '7_IMG_7582.jpg', '8_IMG_7589.jpg', '10_IMG_7662.jpg', '11_IMG_7902.jpg'],
  },
  '2': {
    room: true,
    folder: '2',
    lang: 'rooms',
    images: ['7_IMG_8231.jpg', '9_IMG_8302.jpg', '5_IMG_8249.jpg', '2_IMG_8062.jpg', '10_IMG_8319.jpg', '1_ IMG_8066.jpg', '3_IMG_8065.jpg', '4_IMG_8081.jpg', '6_IMG_8254.jpg', '8_IMG_8270.jpg', '11_IMG_8351.jpg'],
  },
  '3': {
    room: true,
    folder: '3',
    lang: 'rooms',
    images: ['11_IMG_7882.jpg', '7_IMG_7763.jpg', '3_IMG_7734.jpg', '4_IMG_7697.jpg', '8_IMG_7843.jpg', '1_IMG_7711.jpg', '2_IMG_7730.jpg', '5_IMG_7780.jpg', '6_IMG_7755.jpg', '9_IMG_7890.jpg', '10_IMG_7873.jpg', '12_IMG_7862.jpg', '13_IMG_7795.jpg', '14_IMG_7850.jpg'],
  },
  '4': {
    room: true,
    folder: '4',
    lang: 'rooms',
    images: ['1_IMG_8026.jpg', '4_IMG_7970.jpg', '5_IMG_7953.jpg', '6_IMG_7968.jpg', '10_IMG_8005.jpg', '2_IMG_8016.jpg', '3_IMG_8025.jpg', '7_IMG_7973.jpg', '8_IMG_8009.jpg', '9_IMG_7988.jpg', '11_IMG_8039.jpg'],
  },
  activities: {
    room: false,
    folder: 'AKTIVNOSTI',
    lang: 'activities',
    images: ['15.08.2005-050.jpg', '15.08.2005-058.jpg', 'Goniponi-9982.jpg', 'snowman.jpg'],
  },
  surroundings: {
    room: false,
    folder: 'OKOLICA',
    lang: 'surroundings',
    images: [
      'DSC_6315.jpg',
      'Grajsca.jpg',
      'Iris.jpg',
      'Istrski_mak.jpg',
      'Jalovc-1471.jpg',
      'Jalovec.jpg',
      'Jasna.jpg',
      'Jasna_2.jpg',
      'Jasna2.jpg',
      'Jasna2w.jpg',
      'Jasna3.jpg',
      'Jurcek.jpg',
      'Jutro.jpg',
      'Kranjska_Gora.jpg',
      'Kranjska_Gora_april_16.jpg',
      'Kranjska_Gora_jeseni.jpg',
      'Lepi_ceveljc5.jpg',
      'Mahovje-1136.jpg',
      'Medvejek_panorama.jpg',
      'Medvejk-9214.jpg',
      'Podkoren-1989-2.jpg',
      'Ponca.jpg',
      'Slap_Pericnik-5265.jpg',
      'Sleme-p.jpg',
      'snow_and_sun.jpg',
      'Spik-2485.jpg',
      'Srednji_Vrh-7.jpg',
      'Srnjak.jpg',
      'SrV814.jpg',
      'Zela.jpg',
      'zelenci_lake.jpg',
      'Zelenci2.jpg',
      'Zelenci3.jpg',
      'Zelenci-4843.jpg',
    ],
  },
  house: {
    room: false,
    folder: 'HISA',
    lang: 'house',
    images: ['summer.jpg', 'winter.jpg', 'winter2.jpg', 'winter-night.jpg'],
  },
  life: {
    room: false,
    folder: 'ZIVALI,ROZE',
    lang: 'life',
    images: [
      'Caroline-2.jpg',
      'DSC_2031.jpg',
      'Encijan-1382.jpg',
      'ff.jpg',
      'Jur-4434.jpg',
      'mushrooms.jpg',
      'Nagelj-4022.jpg',
      'Osatnik-2326.jpg',
      'Rdeca_musnica.jpg',
      'zvon-8611.jpg',
    ],
  },
  getSectionImages: (section: string): Array<string> => {
    const ret = [];
    allSliderImages[section].images.forEach((img: string) => {
      ret.push('./assets/images/' + allSliderImages[section].folder + '/' + img);
    });
    return ret;
  },
};
