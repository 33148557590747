import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OfferComponent } from './views/offer/offer.component';
import { LocationComponent } from './views/location/location.component';
import { LinksComponent } from './views/links/links.component';
import { GalleryComponent } from './views/gallery/gallery.component';
import { LangSelectComponent } from './views/lang-select/lang-select.component';

const routes: Routes = [
  { path: '', component: OfferComponent, pathMatch: 'full', data: { animation: 'OfferComponent' } },
  { path: 'location', component: LocationComponent, data: { animation: 'LocationComponent' } },
  { path: 'links', component: LinksComponent, data: { animation: 'LinksComponent' } },
  { path: 'gallery', component: GalleryComponent, data: { animation: 'GalleryComponent' } },
  // lang redirect
  { path: 'en', component: LangSelectComponent, data: { lang: 'en' } },
  { path: 'de', component: LangSelectComponent, data: { lang: 'de' } },
  { path: 'it', component: LangSelectComponent, data: { lang: 'it' } },
  // 404
  { path: '**', component: OfferComponent, data: { animation: 'OfferComponent' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
