import { Component, ElementRef, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { heroSwiperConfig } from '../../config/hero-swiper.config';
import { allSliderImages } from '../../config/all-slider-images.config';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
  host: { class: 'route-component' },
})
export class OfferComponent implements OnInit {
  public houseImages = allSliderImages.getSectionImages('house');
  public swiperConfig: SwiperConfigInterface = heroSwiperConfig;

  constructor(protected readonly _elementRef: ElementRef) {}

  public ngOnInit(): void {}

  public moreInfo(): void {
    this._elementRef.nativeElement.querySelector('.room-description').scrollIntoView({ behavior: 'smooth' });
  }
}
