import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

export const heroSwiperConfig: SwiperConfigInterface = {
  slidesPerView: 1,
  pagination: false,
  autoplay: {
    delay: 4000,
    stopOnLastSlide: false,
  },
  navigation: false,
  effect: 'fade',
  speed: 800,
};
