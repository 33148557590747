import { Component, OnInit } from '@angular/core';
import { allSliderImages } from '../../config/all-slider-images.config';
import { roomThumbsSwiperConfig } from '../../config/room-thumbs-swiper.config';

@Component({
  selector: 'app-room-details',
  templateUrl: './room-details.component.html',
  styleUrls: ['./room-details.component.scss'],
})
export class RoomDetailsComponent implements OnInit {
  public rooms = [
    {
      nr: 1,
      persons: 3,
      m2: 35,
      description: 'OFFER.room_1',
      images: allSliderImages.getSectionImages('1'),
      selectedImage: 0,
    },
    {
      nr: 2,
      persons: 5,
      m2: 48,
      description: 'OFFER.room_2',
      images: allSliderImages.getSectionImages('2'),
      selectedImage: 0,
    },
    {
      nr: 3,
      persons: 6,
      m2: 52,
      description: 'OFFER.room_3',
      images: allSliderImages.getSectionImages('3'),
      selectedImage: 0,
    },
    {
      nr: 4,
      persons: 4,
      m2: 35,
      description: 'OFFER.room_4',
      images: allSliderImages.getSectionImages('4'),
      selectedImage: 0,
    },
  ];
  public thumbSwiperConfig = roomThumbsSwiperConfig;

  constructor() {}

  public ngOnInit(): void {}

  public nArray(n: number): Array<any> {
    return Array(n);
  }

  public changeThumbImage(roomInd: number, imageInd: number): void {
    this.rooms[roomInd].selectedImage = imageInd;
  }
}
