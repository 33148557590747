import { Component, OnDestroy, OnInit } from '@angular/core';
import { allSliderImages } from '../../config/all-slider-images.config';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { galleryHeroSwiperConfig } from '../../config/gallery-hero-swiper.config';
import { galleryThumbsSwiperConfig } from '../../config/gallery-thumbs-swiper.config';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  host: { class: 'route-component' },
})
export class GalleryComponent implements OnInit, OnDestroy {
  protected readonly unsubscribe$ = new Subject<boolean>();

  public filters: Array<{ active: boolean; slug: string; name: string }>;
  public allImages: Array<string> = [];
  public heroSwiperConfig = galleryHeroSwiperConfig;
  public thumbSwiperConfig = galleryThumbsSwiperConfig;
  public heroIndex = 0;
  public expandedFilters = true;

  constructor(protected readonly translateService: TranslateService) {}

  public ngOnInit(): void {
    // wait for language to be loaded
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((langChangeEvent: LangChangeEvent) => {
      this.makeFilters();
    });
    // if language is loaded, page change was initiated
    if (this.translateService.store.translations[this.translateService.currentLang]) {
      this.makeFilters();
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(true);
  }

  public toggleFilter(ind: number): void {
    this.filters[ind].active = !this.filters[ind].active;
    this.compileAllImages();
  }

  public changeHeroImage(ind: number): void {
    this.heroIndex = ind;
  }

  public toggleFilters(): void {
    this.expandedFilters = !this.expandedFilters;
  }

  private makeFilters(): void {
    const filt = [];
    Object.keys(allSliderImages).forEach((key: string) => {
      if (typeof allSliderImages[key] === 'object') {
        filt.push({
          active: !allSliderImages[key].room,
          slug: key,
          name: this.compileFilterName(allSliderImages[key]),
        });
      }
    });
    this.filters = filt;
    this.compileAllImages();
  }

  private compileFilterName(config: any): string {
    return this.translateService.instant('GALLERY.' + config.lang) + (config.room ? config.folder : '');
  }

  private compileAllImages(): void {
    let images = [];
    this.filters.forEach((filter: any) => {
      if (filter.active) {
        images = images.concat(allSliderImages.getSectionImages(filter.slug));
      }
    });
    this.heroIndex = 0;
    this.allImages = images;
  }
}
