import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { routeAnimation } from '../../animations/route-animation';
import { RouterOutlet } from '@angular/router';
import { validateLang } from '../../config/translate.config';

@Component({
  selector: 'app-root',
  templateUrl: './layout.component.html',
  animations: [routeAnimation],
})
export class LayoutComponent implements OnInit, OnDestroy {
  protected readonly unsubscribe$ = new Subject<boolean>();

  constructor(private readonly translate: TranslateService, protected readonly cookieService: CookieService) {}

  public ngOnInit(): void {
    this.setTranslation();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(true);
  }

  public setTranslation(): void {
    const lang = validateLang(this.cookieService.get('kosir-lang'));
    this.translate.setDefaultLang('sl');
    this.translate.use(lang);
  }

  public prepareRoute(outlet: RouterOutlet): any {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
