import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-router-links',
  templateUrl: './router-links.component.html',
  styleUrls: ['./router-links.component.scss'],
})
export class RouterLinksComponent implements OnInit, OnDestroy {
  protected readonly unsubscribe$ = new Subject<boolean>();
  public currentUrl: string;

  constructor(private router: Router) {}

  public ngOnInit(): void {
    this.currentUrl = this.router.url.replace('/', '');
    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((event: NavigationEnd) => {
        this.currentUrl = event.url.replace('/', '');
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(true);
  }
}
