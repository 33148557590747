import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

export const galleryThumbsSwiperConfig: SwiperConfigInterface = {
  slidesPerView: 3,
  pagination: false,
  navigation: false,
  mousewheel: true,
  observer: true,
  observeParents: true,
  breakpoints: {
    500: {
      slidesPerView: 4,
    },
    600: {
      slidesPerView: 5,
    },
    720: {
      slidesPerView: 6,
    },
    840: {
      slidesPerView: 7,
    },
    960: {
      slidesPerView: 8,
    },
    1080: {
      slidesPerView: 9,
    },
    1200: {
      slidesPerView: 10,
    },
    1500: {
      slidesPerView: 11,
    },
    1700: {
      slidesPerView: 12,
    },
    1900: {
      slidesPerView: 13,
    },
  },
};
