import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { LayoutComponent } from './views/layout/layout.component';
import { HeaderComponent } from './views/layout/header/header.component';
import { FooterComponent } from './views/layout/footer/footer.component';
import { OfferComponent } from './views/offer/offer.component';
import { TranslateModule } from '@ngx-translate/core';
import { translateConfig } from './config/translate.config';
import { CookieService } from 'ngx-cookie-service';
import { HeaderContactComponent } from './components/header-contact/header-contact.component';
import { RouterLinksComponent } from './components/router-links/router-links.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { RoomDetailsComponent } from './components/room-details/room-details.component';
import { LocationComponent } from './views/location/location.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { LinksComponent } from './views/links/links.component';
import { GalleryComponent } from './views/gallery/gallery.component';
import { LazyLoadDirective } from './directives/lazy-load.directive';
import { LangSelectComponent } from './views/lang-select/lang-select.component';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    OfferComponent,
    HeaderContactComponent,
    RouterLinksComponent,
    RoomDetailsComponent,
    LocationComponent,
    LinksComponent,
    GalleryComponent,
    LazyLoadDirective,
    LangSelectComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SwiperModule,
    TranslateModule.forRoot(translateConfig),
    GoogleMapsModule,
  ],
  providers: [CookieService],
  bootstrap: [LayoutComponent],
})
export class AppModule {}
