import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  host: { class: 'route-component' },
})
export class LocationComponent implements OnInit {
  public center = { lat: 46.483106, lng: 13.781099 };
  public zoom = 14;
  public options = {
    mapTypeId: 'hybrid',
    minZoom: 8,
  };

  public marker = {
    position: this.center,
    title: 'Apartmaji Košir',
    options: {
      icon: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
      animation: google.maps.Animation.BOUNCE,
    },
  };

  constructor() {}

  public ngOnInit(): void {}
}
