import { TranslateLoader } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';

export class WebpackTranslateLoader implements TranslateLoader {
  public getTranslation(lang: string): Observable<any> {
    return from(import(`../../i18n/${lang}.json`));
  }
}

export const translateConfig = {
  loader: {
    provide: TranslateLoader,
    useClass: WebpackTranslateLoader,
  },
};

export const availableLanguages = ['sl', 'en', 'de', 'it'];

export const validateLang = (lang: string): string => {
  return availableLanguages.indexOf(lang) < 0 ? 'sl' : lang;
}
