import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss'],
  host: { class: 'route-component' },
})
export class LinksComponent implements OnInit {
  public linkImages = {
    vopa: './assets/images/links/vopa.png',
    tic: './assets/images/links/tic.png',
    rtc: './assets/images/links/rtc.png',
    korona: './assets/images/links/korona.svg',
    airport: './assets/images/links/airport.svg',
    sz: './assets/images/links/sz.png',
    taxi: './assets/images/links/taxi.svg',
    traffic: './assets/images/links/traffic.svg',
    arriva: './assets/images/links/arriva.svg',
    nomago: './assets/images/links/nomago.jpg',
  };

  constructor() {}

  public ngOnInit(): void {}
}
