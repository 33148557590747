import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

export const galleryHeroSwiperConfig: SwiperConfigInterface = {
  slidesPerView: 1,
  pagination: false,
  navigation: false,
  grabCursor: true,
  spaceBetween: 3,
  observer: true,
  observeParents: true,
  autoplay: {
    delay: 4000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
  },
};
